.header {
	position: fixed;
	left: 0;
	top: 0;

	display: flex;
	justify-content: space-between;

	width: 100%;
	height: 3.5rem;

	background: #673ab8;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	z-index: 50;
}

.header a {
	display: inline-block;
	padding: 0 1rem;
	color: #fff;
	text-decoration: none;
	line-height: 3.5rem;
}

.header a:hover,
.header a:active {
	background: rgba(0, 0, 0, 0.2);
}

.header a.logo {
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
}

.logo h1 {
	padding: 0 0.5rem;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
}

@media (max-width: 639px) {
	.logo h1 {
		display: none;
	}
}

.header nav a.active {
	background: rgba(0, 0, 0, 0.4);
}
